
// @ts-ignore
import CreateTabs from "@/components/Commons/CreateTabs.vue";
import { VerticalNavItemOption } from "@/interfaces/custom_view";
import Vue, { PropType } from "vue";

export default Vue.extend({
	name: "VerticalNav",
	props: {
		current_tab: {
			type: Number,
			default: 0
		},
		options: {
			type: Array as PropType<VerticalNavItemOption[]>,
			default: () => []
		}
	},
	components: { CreateTabs },
	data: () => ({}),
	created() {},
	async mounted() {},
	computed: {
		getItems(): VerticalNavItemOption[] {
			return [
				{
					key: 0,
					tab: this.$t("customize.dimensions.name"),
					disabled: false,
					show: true,
					count: null,
					// @ts-ignore
					src: require(`@/assets/icons/dimensions_icon.png`),
					...this.options[0]
				},
				{
					key: 1,
					tab: this.$t("customize.metrics.name"),
					disabled: false,
					show: true,
					count: null,
					// @ts-ignore
					src: require(`@/assets/icons/metrics_icon.png`),
					...this.options[1]
				}
			];
		}
	},
	methods: {
		updateSelectedTabIndex(index: number) {
			this.$emit("change", index);
		}
	}
});
