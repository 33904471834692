
import Vue from "vue";
import draggable from "vuedraggable";
import { has } from "../../../utils/CustomizeViewData";

export default Vue.extend({
	model: {
		prop: "model_data",
		event: "change",
	},
	props: {
		type: {
			type: String,
			default: "",
		},
		model_data: {
			type: Array,
			default: function () {
				return [];
			},
		},
		fields: {
			type: Array,
			default: function () {
				return [];
			},
		},
		selected_default: {
			type: Array,
			default: function () {
				return [];
			},
		},
	},
	components: { draggable },
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		selectedData: {
			set(val: any) {
				this.$emit("change", val);
			},
			get() {
				return this.model_data;
			},
		},
		getTitle() {
			return this.$t(`customize.${this.type}.selected`);
		},
	},
	methods: {
		/**
		 * Getters
		 */

		getDisplayName(field: any) {
			return this.fields.find((f: any) => f.key === field)?.value;
		},

		/**
		 * Actions
		 */

		remove(data: any) {
			this.$emit("remove", data);
		},

		isDisabled(key: any) {
			return this.selected_default.includes(key);
		},

		hasExternalId() {
			const data: Array<string> = this.selectedData;
			return has(data, 'external_id');
		},

		showChip(field: string) {
			if (field === "id") return !this.hasExternalId();
			return true;
		},
	},
});
