import { Bulk, CreativeSelected } from "@/interfaces/bulk";

export default class BulkEntity implements Bulk {
	show: Boolean = false;
	open: Boolean = false;
	active: Boolean = false;
	loading: Boolean = false;
	selected: CreativeSelected[] = [];

	constructor(show: Boolean) {
		this.show = show;
	}

	getIds() {
		return this.selected.map((s: { id: number }) => s.id);
	}

	hasSelected() {
		return this.selected.length > 0;
	}

	update(bulk: Partial<Bulk>) {
		Object.assign(this, bulk);
	}
}
