
import Vue from "vue";
// @ts-ignore
import CardSearcher from "@/components/Content/CardSearcher.vue";
import { debounce } from "lodash";

export default Vue.extend({
	props: {
		type: {
			type: String,
			default: ""
		},
		is_selected_all: {
			type: Boolean,
			default: false
		},
		fields: {
			type: Array,
			default: function() {
				return [];
			}
		},
		selected_default: {
			type: Array,
			default: function() {
				return [];
			}
		},
		selected: {
			type: Array,
			default: function() {
				return [];
			}
		},
		term: {
			type: String,
			default: ""
		}
	},

	components: {
		CardSearcher
	},

	data: () => ({}),

	created() {},

	mounted() {},

	computed: {
		searchTerm: {
			set(val) {
				this.typingTerm(val);
			},
			get() {
				return this.term;
			}
		},
		isSelectedAll: {
			set(val) {
				this.$emit("toggle-all", val);
			},
			get() {
				return this.is_selected_all;
			}
		},
		dataSelected: {
			set(val) {
				this.$emit("selected", val);
			},
			get() {
				return this.selected;
			}
		}
	},

	methods: {
		typingTerm: debounce(async function(term: string = "") {
			this.$emit("update-term", term);
		}, 500),
		isDisabled(key: any) {
			return this.selected_default.includes(key);
		},
		translate(field: any) {
			return this.$t(`customize.${this.type}.${field}`);
		}
	}
});
