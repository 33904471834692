
import { find, isEmpty, isUndefined } from "lodash";
import Vue from "vue";
// @ts-ignore
import CustomizeView from "@/components/Content/Customize/CustomizeView.vue";
// @ts-ignore
import Dialog from "@/components/Content/Dialogs/Default.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
// @ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
// @ts-ignore
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import { findCustomizeFields } from "@/utils/CustomizeViewData";

import DownloadFile from "./DownloadFile.vue";
// @ts-ignore
import BulkEntity from "@/models/Bulk";
//@ts-ignore
import { TypeLoading } from "@/interfaces/loading";

const DIMENSIONS: string = "dimensions";

export default Vue.extend({
	name: "Buttons",
	props: {
		showTotalizador: {
			type: Boolean,
			default: false,
		},
		showCustomize: {
			type: Boolean,
			default: false,
		},
		totalItems: {
			type: String,
			default: "",
		},
		limit: {
			type: Number,
			default: 25,
		},
		to: {
			type: String,
			default: "/",
		},
		showPeriodSelectable: {
			type: Boolean,
			default: false,
		},
		optionsPeriod: {
			type: Array,
			default: function () {
				return [];
			},
		},
		periodSelectableDisabled: {
			type: Boolean,
			default: false,
		},
		config: {
			type: Object,
			default: function () {
				return {};
			},
		},
		permissionForEntity: {
			type: Object,
			required:true,
			default: function () {
				return {};
			},
		},
		toBulk: {
			type: String,
			default: "/",
		},
		showBulk: {
			type: Boolean,
			default: false,
		},
		titleButtonBulk: {
			type: String,
			default: "Bulk",
		},
		tableHeaders: {
			type: Array,
			default: function () {
				return [];
			},
		},
		filters: {
			type: Object,
			default: function () {
				return {};
			},
		},
		bulk: {
			type: Object,
			default: function () {
				return new BulkEntity(true);
			},
		},
		showDownload: {
			type: Boolean,
			default: true,
		},
		showCreate: {
			type: Boolean,
			default: true,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		entity: {
			type: String,
			default: "Buttons",
			required: false,
		},
	},
	components: {
		CustomizeView,
		Dialog,
		CardActions,
		DownloadFile,
		CardSwitch,
	},
	data: () => ({
		selected_limit_index: 0,
		selected_last_days_index: 0,
		show_rows: [] as any[],
		openDialog: false,
		openEditBulkDialog: false,
		selected_saved: {},
		selected: {},
		selected_default: { all: false, data: [] },
		dataFilter: {},
		hasFiltersApplied: false,
		filtersApplied: [],
	}),

	created() {
		this.show_rows = this.initShowRows();
		this.$nextTick(async () => {
			if (this.showCustomizeView) {
				await this.initializeFields();
			}
		});
	},

	async mounted() {
		this.$nextTick(() => {
			this.dataFilter = this.getFieldByView(this.$route?.name);
			setTimeout(async () => {
				await this.setFiltersApplied();
			}, 1000);
		});
	},

	computed: {
		...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView",
		]),

		...mapGetters("breadcrumbsM", ["getNavegationData"]),

		...mapGetters("profile", ["getAbility"]),

		canCreateBulk(){
			return this.getAbility.can(this.permissionForEntity.actions.create_bulk, this.permissionForEntity.subject);
		},

		canCreate(){
			let res = false;
			switch(this.entity){
				case "reportingOnDemand":
					res = this.getAbility.can(this.permissionForEntity.actions
											.create_scheduler, this.permissionForEntity.subject);
					break;
				case "modifiers":
					res = this.getAbility.can(this.permissionForEntity.subComponent.bid_modifier
										.create_bid, this.permissionForEntity.subject) ||
										this.getAbility.can(this.permissionForEntity.subComponent.delivery_modifier
										.create_delivery, this.permissionForEntity.subject);
					break;
				case "assoLineFromCreative":
					res = this.getAbility.can(this.permissionForEntity.actions.create_assoc, this.permissionForEntity.subject);
					break;
				default:
					res = this.getAbility.can(this.permissionForEntity.actions.create, this.permissionForEntity.subject);
					break;
			}
			return res && this.showCreate;
		},

		canList(){
			let res = false;
			switch(this.entity){
				case "reportingOnDemand":
					res = this.getAbility.can(this.permissionForEntity.actions.index_on_demand, this.permissionForEntity.subject);
					break;
				default:
					res = this.getAbility.can(this.permissionForEntity.actions.index, this.permissionForEntity.subject);
					break;
			}
			return res;
		},

		getTo(){
			return this.getNavegationData("to");
		},

		getSelectedLimit() {
			return find(this.show_rows, { value: this.limit }).text;
		},

		getOptionsPeriod() {
			return this.optionsPeriod;
		},
	
		showCustomizeView() {
			return [
				"AdvertisersIndex",
				"CampaignsIndex",
				"LineItemList",
				"CreativesIndex",
				"SegmentsIndex",
				"CustomListIndex",
				"ModifiersIndex",
				"UsersIndex",
				"RequestCampaignIndex",
				"PrivatePoisCreate",
				"EventIndex",
			].includes(this.$route?.name);
		},

		showEditBulk() {
			let bulk: BulkEntity = this.bulk;
			return bulk.hasSelected();
		},

		getDialogTitle() {
			const title = `bulk.title${this.$route?.name}`;
			return this.$t(title);
		},
	},

	methods: {
		...mapActions("customize_view", ["setFields", "saveSelected"]),
		...mapActions("bulk", ["activeBulk"]),
		...mapActions("loading", ["setLoadingData"]),

		getNameFieldFilter(key: string) {
			let newKey: string = key;
			if (key == "advertiser_id") {
				newKey = "advertiser_name";
			}
			if (key == "campaign") {
				newKey = "campaign_name";
			}

			let metricaKey = this.dataFilter?.metrics?.find(
				(m: { key: string }) => m.key == newKey
			)?.value;

			if (!isUndefined(metricaKey)) return metricaKey;

			let dimensionKey = this.dataFilter?.dimensions?.find(
				(m: { key: string }) => m.key == newKey
			)?.value;

			if (!isUndefined(dimensionKey)) return dimensionKey;

			console.log("dataFilter", {
				key,
				metricaKey,
				dimensionKey,
				newKey,
				dataFilter: this.dataFilter,
			});

			return newKey;
		},

		/**
		 * JSON file
		 * Initialize custom view data (from json file)
		 */
		async initializeFields() {
			// se obtienen los campos filtrado por entidad
			const fieldsFromJson = await this.getFieldsByViewFromJson(
				this.$route?.name
			);

			// se guarda en el store
			await this.setFields({
				view: this.$route?.name,
				fields: fieldsFromJson,
			});

			/**
			 * Store
			 * se obtienen los campos seleccionados filtrado por entidad
			 */
			this.selected = await this.getSelectedByView(this.$route?.name);

			this.selected_saved = {
				view: this.$route?.name,
				tab: DIMENSIONS,
				selected: {
					all: false,
					data: fieldsFromJson?.default || [],
				},
			};

			// Si no hay datos seleccionados setear los valores por defecto

			if (isEmpty(this.selected[DIMENSIONS].data)) {
				/**
				 * Store
				 * se guardan los campos de la entidad
				 */
				await this.handleAction({ type: "init" });

				this.selected = await this.getSelectedByView(this.$route?.name);
			}
		},

		async getFieldsByViewFromJson() {
			return findCustomizeFields(this.$route?.name);
		},

		initShowRows() {
			return [
				{
					value: 25,
					text: this.$t("common.limits.25"),
				},
				{
					value: 50,
					text: this.$t("common.limits.50"),
				},
				{
					value: 100,
					text: this.$t("common.limits.100"),
				},
			];
		},

		selecteLimit(item: { value: number }) {
			this.$emit("selected-limit", item.value);
		},

		handleChange(option: Number) {
			this.$emit("option-period-change", option);
		},

		handleToggleDialog(event: Boolean = false) {
			this.openDialog = event;
		},

		handleToggleEditBulkDialog(event: Boolean = false) {
			let bulk: BulkEntity = this.bulk;
			bulk.update({
				open: event,
			});
		},

		/**
		 * Actions
		 */

		async saveSelectedFields(params: any) {
			return await this.saveSelected(params);
		},

		async handleBulkAction(action: any) {
			let bulk: BulkEntity = this.bulk;
			switch (action.type) {
				case "save":
					const params = {
						entity: this.$route?.name,
						data: bulk.getIds(),
						active: bulk.active,
					};

					bulk.update({
						loading: true,
					});

					this.emitAction(false);

					this.activeBulk(params)
						.then((result: any) => {
							bulk.update({
								open: false,
								loading: false,
								selected: [],
							});
							this.emitAction(true);
						})
						.catch((error: any) => {
							bulk.update({
								open: false,
								loading: false,
								selected: [],
							});
							this.emitAction(true);
						});

					break;

				case "cancel":
					bulk.update({
						open: false,
						selected: [],
					});
					this.emitAction(false);
					break;
			}
		},

		emitAction(makeFetch: Boolean = false) {
			let bulk: BulkEntity = this.bulk;
			makeFetch && this.$emit("fetch");
			this.$emit("update-bulk", bulk);
			this.bulk = bulk;
		},

		async handleAction(action: any) {
			switch (action.type) {
				case "init":
					await this.saveSelectedFields(this.selected_saved);
					break;

				case "save":
					this.$emit("save", this.selected_saved);

					setTimeout(() => {
						this.openDialog = false;
					}, 400);
					break;

				case "save-continue":
					break;

				case "cancel":
					this.openDialog = false;
					break;
			}
		},

		async handleUpdate(event: any) {
			const { view, tab, selected } = event;

			if (!tab || !view || !selected) return;

			this.selected_saved = {
				view,
				tab,
				selected: selected[tab],
			};
		},

		remove(item: any) {
			this.$emit("remove-option", item);
			this.setFiltersApplied();
			this.clearQueryUrl();
		},

		handleBulkActive(item: any) {},

		handleResetColumns() {
			this.$emit("reset-column");
		},

		filtersHasValues() {
			return Object.values(this.filters).some(
				(item: any) => !isEmpty(item)
			);
		},

		clearQueryUrl() {
			this.$router.replace({ query: null });
		},

		async setFiltersApplied() {
			this.filtersApplied = Object.keys(this.filters);
			this.hasFiltersApplied = this.filtersHasValues();
		},
	},
	watch: {
		filters: {
			async handler(val, old) {
				await this.setFiltersApplied();
			},
			deep: true,
		},
		async getTo(val, old) {
			await this.setFiltersApplied();
		},
	},
});
