
import Vue from "vue";
import { typeCalc, getLimitByType } from "../../../utils/CustomizeViewData";

export default Vue.extend({
	model: {
		prop: "model_data",
		event: "input",
	},
	props: {
		model_data: {
			type: Array,
			default: function () {
				return [];
			},
		},
		type: {
			type: String,
			default: "",
		},
	},
	components: {},
	data: () => ({
		resetLoading: false,
	}),
	created() {},
	mounted() {},
	computed: {
		getTitle() {
			return this.$t(`customize.columns`);
		},
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			},
		},
		getItems() {
			const items: Array<any> = this.model_data;
			return items.filter((m) => m.value != "actions");
		},
		getTypeCalc() {
			return typeCalc;
		},
	},
	methods: {
		getHeaders(item: any) {
			let _width = item.width.replace(typeCalc, "");
			_width = `${item.reactive_width}${typeCalc}`;
			this.modelData.map((h: any) => {
				if (h.value != item.value) return h;
				h.width = _width;
				return h;
			});
		},
		getLabel(value: string) {
			return value === "external_id" ? "id" : value;
		},
		getLimitByType(field: string, isMin: Boolean = false) {
			return getLimitByType(field, isMin);
		},
		handleResetColumns() {
			this.resetLoading = true;
			this.$emit("reset-column");
			setTimeout(() => {
				this.resetLoading = false;
			}, 500);
		},
	},
});
