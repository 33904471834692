
import Vue from "vue";
// @ts-ignore
import ParamService from "@/services/params-service";
// @ts-ignore
import { findMatchedRouteNames } from "@/utils/CustomizeViewData";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
//@ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { mapActions } from "vuex";

export default Vue.extend({
	name: "DownloadFile",
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	components: { IconBase },
	data: () => ({}),

	created() {
		this.$nextTick(async () => {});
	},

	mounted() {},

	computed: {},

	methods: {
		...mapActions("loading", ["setLoadingData"]),
		async handleDownload() {
			const path = `${await findMatchedRouteNames(this.$route?.name)}/download`;
			await this.setLoadingData(TypeLoading.loading)
			await this.$store.dispatch(
				path,
				await ParamService.getParams(
					this.config.paginated,
					this.config.filters,
					this.config.option,
					false,
					this.config.fields
				)
			);
			await await this.setLoadingData();
		},
	},
});
